/**
 * rag-terminal Component
 *
 * Connects to a server hosting a terminal emulator and streams its buffer.
 *
 * This component is notably NOT just a view of data that's stored elsewhere in
 * the app. The data is streamed through a websocket connection at the address
 * of the URL attribute.
 *
 * Example Usage:
 *     <rag-console url="wss://hostname/path/to/websocket/endpoint"></rag-console>
 */

import {LitElement, html, css, TemplateResult, property, PropertyValues} from "lit-element";
import protobuf from "protobufjs/light"

//@ts-ignore This will be resolved properly by webpack
import terminalDescriptor from "api/terminal.proto";
const proto = protobuf.Root.fromJSON(terminalDescriptor);


export class TerminalElement extends LitElement {
    @property({type: String})
    public src: string = "";

    // Force the server to resize its buffer according to the size of this client.
    @property({attribute: "force-resize", type: Boolean})
    public forceResize: boolean = false;

    @property({attribute: false})
    public cursorX: number = 0;
    @property({attribute: false})
    public cursorY: number = 0;

    @property()
    private debug = "";

    private ws: WebSocket | null = null;
    private lineHeight: number = 0;

    constructor() {
        super();
        // Make this element focusable
        this.tabIndex = 0;
    }

    // TODO: If the src changes, we need to re-connect and reset the element.

    connectedCallback(): void {
        super.connectedCallback();

        // TODO: handle connect error
        this.ws = new WebSocket(this.src);
        this.ws.onmessage = this.onmessageHandler;
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        if(this.ws) {
            this.ws.close();
        }
    }

    public getBufferHeight(): number {
        return 0;
    }

    public getBufferWidth(): number {
        return 0;
    }

    private onmessageHandler(e: MessageEvent) {
        console.log(e.data);

    }

    private keydownHandler(e: KeyboardEvent) {
        // Do nothing if the latest keypress was a modifier.
        switch (e.key) {
            case "Alt":
            case "Meta":
            case "OS":
            case "Shift":
            case "Control":
            case "Fn":
            case "AltGraph":
                return;
        }

        e.preventDefault();

        // Dummy behavior
        switch (e.key) {
            case "Left":
            case "ArrowLeft":
                this.cursorX && this.cursorX--;
                return;
            case "Right":
            case "ArrowRight":
                this.cursorX++;
                return;
            case "Up":
            case "ArrowUp":
                this.cursorY && this.cursorY--;
                return;
            case "Down":
            case "ArrowDown":
                this.cursorY++;
                return;
            case "Enter":
                this.cursorX = 0;
                this.cursorY++;
                return;
            default: this.cursorX++;
        }
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        this.addEventListener('keydown', this.keydownHandler);

        // TODO: lineHeight should update every time the font style changes
        this.lineHeight = this.calculateLineHeight();
    }

    private getFontSize(): number {
        const fsString = getComputedStyle(this).fontSize;
        if(!fsString) {
            return NaN;
        }
        return parseFloat(fsString);
    }

    private calculateLineHeight(): number {
        const lhString = getComputedStyle(this).lineHeight;
        if(!lhString) {
            throw "no computed line height";
        }

        let lh;
        if(lhString === "normal") {
            lh = this.getFontSize() * 1.2;
        } else {
            lh = parseFloat(lhString);
        }
        if(!lh) {
            throw "line height or font size is not a number";
        }
        return lh;
    }

    static styles = css`
:host {
    background: #e4f5ff;
    display: inline-block;
    font-family: monospace;
    overflow-y: scroll;
    padding: 1em;
    
    --cursor-color: black;
    --cursor-opacity: 1;
    
    /* By default, match ANSI colors with the 16 predefined HTML colors. */
    --ansi-color-black: black;
    --ansi-color-red: maroon;
    --ansi-color-green: green;
    --ansi-color-yellow: olive;
    --ansi-color-blue: navy;
    --ansi-color-magenta: purple;
    --ansi-color-cyan: teal;
    --ansi-color-white: silver;
    --ansi-color-brblack: gray;
    --ansi-color-brred: red;
    --ansi-color-brgreen: lime;
    --ansi-color-bryellow: yellow;
    --ansi-color-brblue: blue;
    --ansi-color-brmagenta: fuchsia;
    --ansi-color-brcyan: aqua;
    --ansi-color-brwhite: white;
}
:host(:focus) {
    outline: 0;
}

.wrapper {
    position: relative;
}
.buffer {
    width: 80ch; /* ch is the width of the 0 character */
}
.line {
    
}
.cursor {
    background: none;
    margin: -1px;
    border: 1px solid var(--cursor-color);
    height: 15px;
    width: 1ch;

    position: absolute;
    top: 0;
    left: 0;
}
:host(.cursor-line) .cursor {
    width: 0;
}
:host(:focus) .cursor {
    background: var(--cursor-color);
    // animation: blink 1s step-start infinite;
    opacity: var(--cursor-opacity);
}
@keyframes blink {
    50% {
        opacity: 0;
    }
}
`;

    protected render(): TemplateResult | void {
        return html`
<div class="wrapper">
<div class="buffer">
<div class="line">Here's some text</div>
<div class="line">that might go</div>
<div class="line">inside our terminal thingy</div>
<div class="line">${this.debug}</div>
</div>

<div class="cursor" style="
    left:${Math.round(this.cursorX)}ch;
    top:${Math.round(this.cursorY * this.lineHeight)}px"></div>
</div>`;
    }
}

customElements.define("rag-terminal", TerminalElement);
