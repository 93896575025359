import {html, PropertyValues} from "lit-element";
import CodeMirror from "codemirror";
import "codemirror/keymap/sublime"
import "codemirror/mode/shell/shell"
import "codemirror/addon/selection/mark-selection"
import {CellElement} from "./cell";

import "codemirror/lib/codemirror.css"

export class PromptElement extends CellElement {
    private editor: CodeMirror.Editor = CodeMirror(null as any, {
        keyMap: "sublime",
        lineNumbers: false,
        mode: "shell",
        extraKeys: {
            Enter: (cm: CodeMirror.Editor) => this.onEnter(cm)
        }
    });

    protected onEnter(cm: CodeMirror.Editor) {
        let event = new CustomEvent("commandenter", {
            bubbles: true,
            detail: {
                commandString: cm.getValue()
            }
        });
        this.dispatchEvent(event);

        cm.setValue("");
    }

    connectedCallback() {
        super.connectedCallback();
        this.classList.add("current");
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        this.editor.refresh();
    }

    render() {
        return html`${this.editor.getWrapperElement()}`;
    }
}

customElements.define("rag-prompt", PromptElement);
