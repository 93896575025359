/**
 * Raggedy Console Client
 *
 * We may eventually want to have two main entry points,
 * one that's local (e.g. for a PWA) and one that's downloaded from the server.
 *
 * @author Subhav Ramachandran <subhav@raggedy.org>
 * @license GNU GPL v3.0
 */

import {
    Widget,
    DockPanel,
    Menu,
    MenuBar
} from "@phosphor/widgets";
import "@phosphor/default-theme/style/index.css";

import {commands} from "./commands";
import {Session} from "./session";
import "./components/console/console";
import "./components/terminal/terminal";
import "./components/statusbar/statusbar";
import {StatusbarElement} from "./components/statusbar/statusbar";

/**
 * Set up the PhosphorJS UI Shell.
 */
export function loadUI() {
    let menu1 = new Menu({commands: commands});
    menu1.title.label = "Session";
    menu1.addItem({command: "new"});
    menu1.addItem({command: "reconnect"});
    menu1.addItem({command: "duplicate"});
    let menu2 = new Menu({commands: commands});
    menu2.title.label = "Edit";
    menu2.addItem({command: "copy"});
    menu2.addItem({command: "paste"});
    let menu3 = new Menu({commands: commands});
    menu3.title.label = "View";
    let menu3Sub1 = new Menu({commands: commands});
    menu3Sub1.title.label = "Toolbars";
    menu3Sub1.addItem({command: "toggle-statusbar"});
    menu3.addItem({type: "submenu", submenu: menu3Sub1});
    let menu4 = new Menu({commands: commands});
    menu4.title.label = "Log";
    menu4.addItem({command: "open-log"});
    menu4.addItem({command: "save-log"});
    let menu5 = new Menu({commands: commands});
    menu5.title.label = "Help";
    menu5.addItem({command: "about"});

    let bar = new MenuBar();
    bar.addMenu(menu1);
    bar.addMenu(menu2);
    bar.addMenu(menu3);
    bar.addMenu(menu4);
    bar.addMenu(menu5);

    let dock = new DockPanel({mode: "multiple-document", spacing: 1});
    dock.id = "dock";

    let i = 0;
    commands.addCommand("new", {
        label: "New...",
        execute: () => {
            let s = new Session();
            let w = new Widget({node: s.console});
            i++;
            w.title.label = "Session " + i;
            w.title.caption = "Session " + i;
            w.title.closable = true;
            dock.addWidget(w);
            dock.activateWidget(w);
        },
        mnemonic: 0
    });

    let statusbar = new Widget({node: document.createElement("rag-statusbar") as StatusbarElement});

    Widget.attach(bar, document.body);
    Widget.attach(dock, document.body);
    Widget.attach(statusbar, document.body);
    window.onresize = () => { dock.update(); };
}

/**
 * Entry point to the app.
 *
 * This should be split apart from the components in the bundle so that
 * components can be used and tested separately. We have some options:
 * 1.  Build the components independently as separate entry points.
 * 2.  Wrap the phosphor UI in a `rag-app` or `rag-ui` component.
 * 3.  Build the rest of the app as a separate library.
 * 4.  Set a global `window.raggedyConsole` from this module where we would
 *     export an init function.
 */
function main() {
    document.title = "Console";

    loadUI();
    commands.execute("new");
}

//@ts-ignore Force create a global variable.
window.raggedyMain = main;

// window.onload = main;
