/**
 * rag-console Component
 *
 * The main console element.
 *
 * Unlike rag-terminal, this is not a standalone component -- it's just the view
 * for the application to use.
 */

import {LitElement, html, css, PropertyValues, property} from "lit-element";
import CodeMirror from "codemirror";
import "codemirror/keymap/sublime"
import "codemirror/mode/shell/shell"
import "codemirror/addon/selection/mark-selection"
import "@types/codemirror/codemirror-runmode.d"
import "codemirror/addon/runmode/runmode"

import "codemirror/lib/codemirror.css"

import "./outputcell"
import "./inputcell"
import "./prompt"

// Add missing CodeMirror addon definitions
declare module "codemirror" {
    interface EditorConfiguration {
        styleSelectedText?: boolean;
    }
}

enum CellType {
    Empty,
    Input,
    Return,
    Info,
    Error,
    // Terminal,
}

function classFromCellType(type: CellType): string {
    switch (type) {
        case CellType.Empty: return "";
        case CellType.Input: return "input";
        case CellType.Return: return "return";
        case CellType.Info: return "info";
        case CellType.Error: return "error";
    }
}

interface Cell {
    type: CellType;
    content: Node;
}

function enterHandler(console: ConsoleElement, cm: CodeMirror.Editor) {
    // TODO: Fire an event instead of making changes here

    const val = cm.getValue();
    if (val === "") {
        return;
    }
    cm.setValue("");

    let inputElement = document.createElement("pre");
    inputElement.classList.add("cm-s-default"); // Hack to apply the theme.
    CodeMirror.runMode(val, "shell", inputElement, {
    });

    // Apparently lit-element only updates if the whole element
    // changes. Need to use concat and not push :(
    console.cells = console.cells.concat([
        {
            type: CellType.Input,
            content: inputElement
        },
        {
            type: CellType.Return,
            content: createDummyPre("Example return text.")
        },
    ]);
}

function createDummyPre(str: string) {
    let el = document.createElement("pre");
    el.appendChild(document.createTextNode(str));
    return el;
}

export class ConsoleElement extends LitElement {
    private editor: CodeMirror.Editor = CodeMirror(null as any, {
        keyMap: "sublime",
        lineNumbers: false,
        lineWrapping: true,
        mode: "shell",
        styleSelectedText: true,
        extraKeys: {
            Enter: (cm: CodeMirror.Editor) => enterHandler(this, cm)
        }
    });

    @property({type: Array, attribute: false})
    public cells: Cell[] = [
        {
            type: CellType.Input,
            content: createDummyPre("Input")
        },
        {
            type: CellType.Return,
            content: createDummyPre("Return")
        },
        {
            type: CellType.Info,
            content: document.createTextNode("Info")
        },
        {
            type: CellType.Error,
            content: createDummyPre("Error")
        },
    ];

    protected createRenderRoot(): Element | ShadowRoot {
        // Render to Light DOM (lets us make CSS global)
        return this;
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        this.editor.refresh();
    }

    // TODO: this doesn't seem to do anything with the light DOM
    static styles = css`
:host {
    display: block;
}
`;

    protected render() {
        return html`
${this.cells.map(cell => html`<div class="rag-console-cell ${classFromCellType(cell.type)}">${cell.content}</div>`)}
<div class="rag-console-cell current">${this.editor.getWrapperElement()}</div>`
    }
}
customElements.define("rag-console", ConsoleElement);
