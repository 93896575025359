/**
 * StatusbarElement shows a status bar.
 */

import {LitElement, html, css} from "lit-element";

export class StatusbarElement extends LitElement {
    protected firstUpdated() {
        // Prevent right clicks
        this.addEventListener("contextmenu", function(e) {
            e.preventDefault();
            return false;
        });
    }

    static styles = css`
:host {
    display: flex;
    flex-direction: row;
    user-select: none;
}
div {
    display: inline-block;
}
`;

    protected render() {
        return html`
<div>Bash: 1 Job</div>
<div style="flex-grow: 1"></div>
<div>Connected to user@hostname</div>
`;
    }
}

customElements.define("rag-statusbar", StatusbarElement);
