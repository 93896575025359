import {CommandRegistry} from "@phosphor/commands";

export let commands = new CommandRegistry();

function doNothing() {
    console.log("did nothing");
}
commands.addCommand("duplicate", {
    label: "Duplicate",
    execute: doNothing,
    mnemonic: 0
});
commands.addCommand("reconnect", {
    label: "Reconnect...",
    execute: doNothing,
    mnemonic: 0
});

commands.addCommand("copy", {
    label: "Copy",
    execute: doNothing
});
commands.addCommand("paste", {
    label: "Paste",
    execute: doNothing,
});
commands.addCommand("toggle-statusbar", {
    label: "Status Bar",
    execute: doNothing,
    isToggled: () => true
});
commands.addCommand("open-log", {
    label: "Open...",
    execute: doNothing
});
commands.addCommand("save-log", {
    label: "Save...",
    execute: doNothing
});
commands.addCommand("about", {
    label: "About Console...",
    execute: doNothing
});
commands.addKeyBinding({
    command: "paste",
    keys: ["Ctrl V"],
    selector: "body",
});
