/**
 * Session Controller
 *
 * Maintains the state of a session and controls a ConsoleElement.
 */
import {ConsoleElement} from "./components/console/console";

export class Session {
    private consoleElement: ConsoleElement;

    constructor() {
        this.consoleElement = document.createElement("rag-console") as ConsoleElement;
    }

    get console(): ConsoleElement {
        return this.consoleElement;
    }
}
