/**
 * rag-cell-input Component
 */

import {html, property} from "lit-element";
import CodeMirror from "codemirror";
import "codemirror/mode/shell/shell"
import "@types/codemirror/codemirror-runmode.d"
import "codemirror/addon/runmode/runmode"
import {CellElement} from "./cell";

import "codemirror/lib/codemirror.css"

export class InputCellElement extends CellElement {
    @property({type: String, attribute: false})
    public command: string = 'echo "Hello World"';

    @property({type: String})
    public src: string = "";

    constructor() {
        super();

        // If src is set, set `command` by fetching from the source.
        // Must be plain text.
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.classList.add("input");
    }

    render() {
        let inputElement = document.createElement("pre");
        inputElement.classList.add("cm-s-default"); // Hack to apply the theme.
        CodeMirror.runMode(this.command, "shell", inputElement);

        return html`<pre class="cm-s-default"><slot></slot></pre>`;
    }
}

customElements.define("rag-cell-input", InputCellElement);
