/**
 * CellElement is the base class for a console cell.
 */

import {LitElement, css} from "lit-element";

// TODO: can this be made abstract?
export class CellElement extends LitElement {
    private addedClass: boolean = false;

    protected constructor() {
        super();
    }

    public connectedCallback() {
        super.connectedCallback();
        if (!this.addedClass) {
            this.classList.add("rag-console-cell");
            this.addedClass = true;
        }
    }

    protected createRenderRoot(): Element | ShadowRoot {
        // Render to Light DOM (lets us make CSS global)
        return this;
    }
}
