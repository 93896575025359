/**
 * rag-cell-output Component
 */

import {html, property} from "lit-element";
import {CellElement} from "./cell";

class OutputCellElement extends CellElement {
    @property({type: String})
    public src: string = "";

    connectedCallback() {
        super.connectedCallback();
        this.classList.add("output");

        // Fetch output text from src. MIME type can be one of
        // 1. plain text
        // 2. HTML document
    }

    render() {
        return html``;
    }
}

customElements.define("rag-cell-output", OutputCellElement);


// This doesn't have to be a separate element. Can just be an output element
// with the `error` class.
class ErrorCellElement extends OutputCellElement {
    connectedCallback() {
        super.connectedCallback();
        this.classList.remove("output");
        this.classList.add("error");
    }
}
customElements.define("rag-cell-error", ErrorCellElement);
